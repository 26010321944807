require("./bootstrap");
require("../../node_modules/jquery-spamguard/dist/jquery.spamguard.js");

import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: "https://2834e4ee76b14786bce0dd567c8c426d@o170216.ingest.sentry.io/6150858",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.5,
});
